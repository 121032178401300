exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-branches-index-js": () => import("./../../../src/pages/branches/index.js" /* webpackChunkName: "component---src-pages-branches-index-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-component-status-index-js": () => import("./../../../src/pages/resources/component-status/index.js" /* webpackChunkName: "component---src-pages-resources-component-status-index-js" */),
  "component---src-pages-resources-icons-index-js": () => import("./../../../src/pages/resources/icons/index.js" /* webpackChunkName: "component---src-pages-resources-icons-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-component-js": () => import("./../../../src/templates/component.js" /* webpackChunkName: "component---src-templates-component-js" */),
  "component---src-templates-css-js": () => import("./../../../src/templates/css.js" /* webpackChunkName: "component---src-templates-css-js" */),
  "component---src-templates-javascript-js": () => import("./../../../src/templates/javascript.js" /* webpackChunkName: "component---src-templates-javascript-js" */),
  "component---src-templates-webcomponents-js": () => import("./../../../src/templates/webcomponents.js" /* webpackChunkName: "component---src-templates-webcomponents-js" */)
}

